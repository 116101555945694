const firebaseConfig = {
  apiKey: "AIzaSyCdApASUjGNbmyo7oR27V9NHCCz_ozT89M",
  authDomain: "memberjci-e5a4f.firebaseapp.com",
  databaseURL: "https://memberjci-e5a4f.firebaseio.com",
  projectId: "memberjci-e5a4f",
  storageBucket: "memberjci-e5a4f.appspot.com",
  messagingSenderId: "28378231644",
  appId: "1:28378231644:web:02586f49b8afe9f5c38799"
};

// Cloud Functions
const backendUrl = `https://us-central1-${
  firebaseConfig.projectId
}.cloudfunctions.net/api/api`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export const environment = {
  production: false,
  firebaseConfig,
  backendUrl,
};
