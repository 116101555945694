const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    start: 'Start',
    end: 'End'
  },

  app: {
    title: 'Application',
  },

  entities: {
    userAccount: {
      name: 'userAccount',
      label: 'UserAccounts',
      menu: 'UserAccounts',
      exporterFileName: 'userAccount_export',
      list: {
        menu: 'UserAccounts',
        title: 'UserAccounts',
      },
      create: {
        success: 'User Account saved successfully',
      },
      update: {
        success: 'User Account saved successfully',
      },
      destroy: {
        success: 'User Account deleted successfully',
      },
      destroyAll: {
        success: 'User Account(s) deleted successfully',
      },
      edit: {
        title: 'Edit User Account',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'nameChinese': 'Name (Chinese)',
        'nameNick': 'Nick Name',
        'gender': 'Gender',
        'iCNumber': 'IC Number',
        'dateOfBirthRange': 'Date Of Birth',
        'dateOfBirth': 'Date Of Birth',
        'address': 'Address',
        'email': 'Email',
        'contactMobile': 'Contact (Mobile)',
        'contactHome': 'Contact (Home)',
        'occupation': 'Occupation',
        'contactEmergency': 'Contact Emergency',
        'photo': 'Photo',
        'status': 'Status',
        'dateJoinedRange': 'Date Joined',
        'dateJoined': 'Date Joined',
        'actions': 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'gender': {
          'Male': 'Male',
          'Female': 'Female',
        },
        'status': {
          'Non_Member': 'Non_Member',
          'Provisional_Member': 'Provisional_Member',
          'Member': 'Member',
          'Alumni': 'Alumni',
        },
        'actions': {
          'Active': 'Active',
          'Block': 'Block',
        },
      },
      new: {
        title: 'New User Account',
      },
      view: {
        title: 'View User Account',
      },
      importer: {
        title: 'Import UserAccounts',
        fileName: 'userAccount_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    member: {
      name: 'member',
      label: 'Members',
      menu: 'Members',
      exporterFileName: 'member_export',
      list: {
        menu: 'Members',
        title: 'Members',
      },
      create: {
        success: 'Member saved successfully',
      },
      update: {
        success: 'Member saved successfully',
      },
      destroy: {
        success: 'Member deleted successfully',
      },
      destroyAll: {
        success: 'Member(s) deleted successfully',
      },
      edit: {
        title: 'Edit Member',
      },
      fields: {
        id: 'Id',
        'memberID': 'MemberID',
        'name': 'Name',
        'membershipType': 'Membership Type',
        'membershipStatus': 'Membership Status',
        'membershipAction': 'Membership Action',
        'financialMember': 'FinancialMember',
        'financialAction': 'Financial Action',
        'nameofIntroducer': 'Name of Introducer (if have)',
        'areYourIntroducerIsJCIMember': 'Are Your Introducer Is JCI Member?',
        'tShirtSize': 'TShirt Size',
        'bloodType': 'BloodType',
        'maritalStatus': 'Marital Status',
        'companyName': 'Company Name',
        'positionInCompany': 'Position In Company',
        'howKnowJCI': 'How your know about JCI MCE',
        'individual': 'Do u interested in Individual Project or Event',
        'business': 'Do u interested in Business Project or Event',
        'community': 'Do u interested in Community Project or Event',
        'international': 'Do u interested in International Project or Event',
        'obligation': 'Obligation',
        'informationGiven': 'I hereby declare that the above information is true and correct',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'membershipType': {
          'Member': 'Member',
          'Alumni': 'Alumni',
        },
        'membershipStatus': {
          'Pending_Approve': 'Pending_Approve',
          'Provisional': 'Provisional',
          'Member': 'Member',
          'Alumni': 'Alumni',
        },
        'membershipAction': {
          'Approve_Provisional': 'Approve_Provisional',
          'Approve_Member': 'Approve_Member',
          'Approve_Alumni': 'Approve_Alumni',
          'Reject': 'Reject',
        },
        'financialMember': {
          'Awaiting_Payment': 'Awaiting_Payment',
          'Paid_Provisional': 'Paid_Provisional',
          'Paid_Member': 'Paid_Member',
          'Paid_Alumni': 'Paid_Alumni',
        },
        'financialAction': {
          'Paid_Provisonal': 'Paid_Provisonal',
          'Paid_Member': 'Paid_Member',
          'Paid_Alumni': 'Paid_Alumni',
        },
        'areYourIntroducerIsJCIMember': {
          'Yes': 'Yes',
          'No': 'No',
        },
        'tShirtSize': {
          'XS': 'XS',
          'S': 'S',
          'M': 'M',
          'L': 'L',
          'XL': 'XL',
          'XXL': 'XXL',
          'XXXL': 'XXXL',
          'XXXXL': 'XXXXL',
        },
        'bloodType': {
          'A_Minus': 'A_Minus',
          'A_Plus': 'A_Plus',
          'B_Minus': 'B_Minus',
          'B_Plus': 'B_Plus',
          'AB_Minus': 'AB_Minus',
          'AB_Plus': 'AB_Plus',
          'O_Minus': 'O_Minus',
          'O_Plus': 'O_Plus',
        },
        'maritalStatus': {
          'Single': 'Single',
          'Married': 'Married',
          'Divorced': 'Divorced',
          'Widowed': 'Widowed',
        },
        'howKnowJCI': {
          'Family': 'Family',
          'Friend': 'Friend',
          'Social_Media': 'Social_Media',
          'Event': 'Event',
          'Newspaper': 'Newspaper',
        },
        'obligation': {
          'Yes': 'Yes',
          'No': 'No',
        },
        'informationGiven': {
          'Yes': 'Yes',
          'No': 'No',
        },
      },
      new: {
        title: 'New Member',
      },
      view: {
        title: 'View Member',
      },
      importer: {
        title: 'Import Members',
        fileName: 'member_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    activity: {
      name: 'activity',
      label: 'Activities',
      menu: 'Activities',
      exporterFileName: 'activity_export',
      list: {
        menu: 'Activities',
        title: 'Activities',
      },
      create: {
        success: 'Activity saved successfully',
      },
      update: {
        success: 'Activity saved successfully',
      },
      destroy: {
        success: 'Activity deleted successfully',
      },
      destroyAll: {
        success: 'Activity(s) deleted successfully',
      },
      edit: {
        title: 'Edit Activity',
      },
      fields: {
        id: 'Id',
        'eventName': 'Event Name',
        'dateRange': 'Date',
        'date': 'Date',
        'time': 'Time',
        'venue': 'Venue',
        'eventDescription': 'Event Description',
        'memberPrice': 'MemberPrice',
        'jCIPrice': 'JCIPrice',
        'nonMemberPrice': 'NonMemberPrice',
        'proposal': 'Proposal',
        'enhancementReport': 'Enhancement Report',
        'financialReport': 'Financial Report',
        'status': 'Status',
        'statusAction': 'StatusAction',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'status': {
          'Awaiting_Approved': 'Awaiting_Approved',
          'Approved': 'Approved',
          'Rejected': 'Rejected',
        },
        'statusAction': {
          'Approve': 'Approve',
          'Reject': 'Reject',
        },
      },
      new: {
        title: 'New Activity',
      },
      view: {
        title: 'View Activity',
      },
      importer: {
        title: 'Import Activities',
        fileName: 'activity_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    business: {
      name: 'business',
      label: 'Businesses',
      menu: 'Businesses',
      exporterFileName: 'business_export',
      list: {
        menu: 'Businesses',
        title: 'Businesses',
      },
      create: {
        success: 'Business saved successfully',
      },
      update: {
        success: 'Business saved successfully',
      },
      destroy: {
        success: 'Business deleted successfully',
      },
      destroyAll: {
        success: 'Business(s) deleted successfully',
      },
      edit: {
        title: 'Edit Business',
      },
      fields: {
        id: 'Id',
        'companyName': 'Company Name',
        'industry': 'Industry',
        'address': 'Address',
        'contact': 'Contact',
        'email': 'Email',
        'website': 'Website',
        'image': 'Image (5 images only)',
        'companyDescription': 'Company Description',
        'memberBenefit': 'Member Benefit',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'industry': {
          'Agriculture': 'Agriculture',
          'Fishing': 'Fishing',
          'Mining_and_Quarrying': 'Mining_and_Quarrying',
          'Oil_and_Gas': 'Oil_and_Gas',
          'Manufacturing': 'Manufacturing',
          'Construction': 'Construction',
          'Commerce': 'Commerce',
          'Utility': 'Utility',
          'Wholesale_and_Retail': 'Wholesale_and_Retail',
          'Hotel': 'Hotel',
          'Restaurants': 'Restaurants',
          'Logistic_and_Transport': 'Logistic_and_Transport',
          'Arts_and_Entertainment': 'Arts_and_Entertainment',
          'Sport': 'Sport',
          'Information_Technology': 'Information_Technology',
          'Transportation': 'Transportation',
          'Financial_and_Insurance': 'Financial_and_Insurance',
          'Real_Estate_and_Rental': 'Real_Estate_and_Rental',
          'Waste_Management': 'Waste_Management',
          'Education': 'Education',
          'Health': 'Health',
          'Social_Work': 'Social_Work',
          'Safety_and_Defense': 'Safety_and_Defense',
          'Forestry': 'Forestry',
          'Sales_and_Service': 'Sales_and_Service',
        },
      },
      new: {
        title: 'New Business',
      },
      view: {
        title: 'View Business',
      },
      importer: {
        title: 'Import Businesses',
        fileName: 'business_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    gallery: {
      name: 'gallery',
      label: 'Galleries',
      menu: 'Galleries',
      exporterFileName: 'gallery_export',
      list: {
        menu: 'Galleries',
        title: 'Galleries',
      },
      create: {
        success: 'Gallery saved successfully',
      },
      update: {
        success: 'Gallery saved successfully',
      },
      destroy: {
        success: 'Gallery deleted successfully',
      },
      destroyAll: {
        success: 'Gallery(s) deleted successfully',
      },
      edit: {
        title: 'Edit Gallery',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'image': 'Image',
        'category': 'Category',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Gallery',
      },
      view: {
        title: 'View Gallery',
      },
      importer: {
        title: 'Import Galleries',
        fileName: 'gallery_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    type: {
      name: 'type',
      label: 'Types',
      menu: 'Types',
      exporterFileName: 'type_export',
      list: {
        menu: 'Types',
        title: 'Types',
      },
      create: {
        success: 'Type saved successfully',
      },
      update: {
        success: 'Type saved successfully',
      },
      destroy: {
        success: 'Type deleted successfully',
      },
      destroyAll: {
        success: 'Type(s) deleted successfully',
      },
      edit: {
        title: 'Edit Type',
      },
      fields: {
        id: 'Id',
        'text': 'Text',
        'integerRange': 'Integer',
        'integer': 'Integer',
        'decimalRange': 'Decimal',
        'decimal': 'Decimal',
        'dateRange': 'Date',
        'date': 'Date',
        'datetimeRange': 'Datetime',
        'datetime': 'Datetime',
        'booleanSwitch': 'BooleanSwitch',
        'booleanCheckbox': 'BooleanCheckbox',
        'booleanRadio': 'BooleanRadio',
        'enumerator': 'Enumerator',
        'relationOne': 'RelationOne',
        'relationMany': 'RelationMany',
        'relationUserOne': 'RelationUserOne',
        'relationUserMany': 'RelationUserMany',
        'file': 'File',
        'image': 'Image',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'enumerator': {
          'Start': 'Start',
          'Progress': 'Progress',
          'End': 'End',
        },
      },
      new: {
        title: 'New Type',
      },
      view: {
        title: 'View Type',
      },
      importer: {
        title: 'Import Types',
        fileName: 'type_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    galleryCategory: {
      name: 'galleryCategory',
      label: 'Gallery Categories',
      menu: 'Gallery Categories',
      exporterFileName: 'galleryCategory_export',
      list: {
        menu: 'Gallery Categories',
        title: 'Gallery Categories',
      },
      create: {
        success: 'Gallery Category saved successfully',
      },
      update: {
        success: 'Gallery Category saved successfully',
      },
      destroy: {
        success: 'Gallery Category deleted successfully',
      },
      destroyAll: {
        success: 'Gallery Category(s) deleted successfully',
      },
      edit: {
        title: 'Edit Gallery Category',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Gallery Category',
      },
      view: {
        title: 'View Gallery Category',
      },
      importer: {
        title: 'Import Gallery Categories',
        fileName: 'galleryCategory_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount:
      'Already have an account? Sign in.',
    signinWithAnotherAccount:
      'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message:
        'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    userAccountEditor: {
      label: 'User Account Editor',
      description: 'Edit access to UserAccounts',
    },
    userAccountViewer: {
      label: 'User Account Viewer',
      description: 'View access to UserAccounts',
    },
    memberEditor: {
      label: 'Member Editor',
      description: 'Edit access to Members',
    },
    memberViewer: {
      label: 'Member Viewer',
      description: 'View access to Members',
    },
    activityEditor: {
      label: 'Activity Editor',
      description: 'Edit access to Activities',
    },
    activityViewer: {
      label: 'Activity Viewer',
      description: 'View access to Activities',
    },
    businessEditor: {
      label: 'Business Editor',
      description: 'Edit access to Businesses',
    },
    businessViewer: {
      label: 'Business Viewer',
      description: 'View access to Businesses',
    },
    galleryEditor: {
      label: 'Gallery Editor',
      description: 'Edit access to Galleries',
    },
    galleryViewer: {
      label: 'Gallery Viewer',
      description: 'View access to Galleries',
    },
    typeEditor: {
      label: 'Type Editor',
      description: 'Edit access to Types',
    },
    typeViewer: {
      label: 'Type Viewer',
      description: 'View access to Types',
    },
    galleryCategoryEditor: {
      label: 'Gallery Category Editor',
      description: 'Edit access to Gallery Categories',
    },
    galleryCategoryViewer: {
      label: 'Gallery Category Viewer',
      description: 'View access to Gallery Categories',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists:
        'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
      primaryColor: 'Primary Color',
      secondaryColor: 'Secondary Color',
    },
    colors: {
      default: 'Default',
      amber: 'Amber',
      blue: 'Blue',
      cyan: 'Cyan',
      deep_orange: 'Deep Orange',
      deep_purple: 'Deep Purple',
      green: 'Green',
      indigo: 'Indigo',
      light_blue: 'Light Blue',
      light_green: 'Light Green',
      lime: 'Lime',
      orange: 'Orange',
      pink: 'Pink',
      purple: 'Purple',
      red: 'Red',
      teal: 'Teal',
      yellow: 'Yellow',
      grey: 'Grey',
      blue_grey: 'Blue Grey',
      brown: 'Brown',
    },
  },
  home: {
    menu: 'Home',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/app/home/home.component.html.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf:
        '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length:
        '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches:
        '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min:
        '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      type: '${path} must be an number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max:
        '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `Status: {0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  paginator: {
    itemsPerPageLabel: 'Items per page:',
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    firstPageLabel: 'First page',
    lastPageLabel: 'Last page',
    getRangeLabel: {
      zero: '0 of {0}',
      interval: '{0} - {1} of {2}',
    },
  },

  datetime: {
    upSecondLabel: 'Add a second',
    downSecondLabel: 'Minus a second',
    upMinuteLabel: 'Add a minute',
    downMinuteLabel: 'Minus a minute',
    upHourLabel: 'Add a hour',
    downHourLabel: 'Minus a hour',
    prevMonthLabel: 'Previous month',
    nextMonthLabel: 'Next month',
    prevYearLabel: 'Previous year',
    nextYearLabel: 'Next year',
    prevMultiYearLabel: 'Previous 21 years',
    nextMultiYearLabel: 'Next 21 years',
    switchToMonthViewLabel: 'Change to month view',
    switchToMultiYearViewLabel: 'Choose month and year',
    cancelBtnLabel: 'Cancel',
    setBtnLabel: 'Set',
    rangeFromLabel: 'From',
    rangeToLabel: 'To',
    hour12AMLabel: 'AM',
    hour12PMLabel: 'PM',
  },

  table: {
    noData: 'No data',
    loading: 'Loading...',
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential':
      'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use':
      'Credentials are already in use',
  },
};

export default en;
