const ptBR = {
  common: {
    or: 'ou',
    cancel: 'Cancelar',
    reset: 'Limpar',
    save: 'Salvar',
    search: 'Buscar',
    edit: 'Editar',
    remove: 'Remover',
    new: 'Novo',
    export: 'Exportar para Excel',
    noDataToExport: 'Não há dados para exportar',
    import: 'Importar',
    discard: 'Descartar',
    yes: 'Sim',
    no: 'Não',
    pause: 'Pausar',
    areYouSure: 'Tem certeza?',
    view: 'Visualizar',
    destroy: 'Deletar',
    mustSelectARow: 'Selecine uma linha',
    start: 'Início',
    end: 'Fim'
  },

  app: {
    title: 'Aplicação',
  },

  entities: {
    userAccount: {
      name: 'User Account',
      label: 'UserAccounts',
      menu: 'UserAccounts',
      exporterFileName: 'User Account_exportados',
      list: {
        menu: 'UserAccounts',
        title: 'UserAccounts',
      },
      create: {
        success: 'User Account salvo com sucesso',
      },
      update: {
        success: 'User Account salvo com sucesso',
      },
      destroy: {
        success: 'User Account deletado com sucesso',
      },
      destroyAll: {
        success: 'User Account(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar User Account',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'nameChinese': 'Name (Chinese)',
        'nameNick': 'Nick Name',
        'gender': 'Gender',
        'iCNumber': 'IC Number',
        'dateOfBirthRange': 'Date Of Birth',
        'dateOfBirth': 'Date Of Birth',
        'address': 'Address',
        'email': 'Email',
        'contactMobile': 'Contact (Mobile)',
        'contactHome': 'Contact (Home)',
        'occupation': 'Occupation',
        'contactEmergency': 'Contact Emergency',
        'photo': 'Photo',
        'status': 'Status',
        'dateJoinedRange': 'Date Joined',
        'dateJoined': 'Date Joined',
        'actions': 'Actions',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        'gender': {
          'Male': 'Male',
          'Female': 'Female',
        },
        'status': {
          'Non_Member': 'Non_Member',
          'Provisional_Member': 'Provisional_Member',
          'Member': 'Member',
          'Alumni': 'Alumni',
        },
        'actions': {
          'Active': 'Active',
          'Block': 'Block',
        },
      },
      new: {
        title: 'Novo User Account',
      },
      view: {
        title: 'Visualizar User Account',
      },
      importer: {
        title: 'Importar UserAccounts',
        fileName: 'userAccount_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    member: {
      name: 'Member',
      label: 'Members',
      menu: 'Members',
      exporterFileName: 'Member_exportados',
      list: {
        menu: 'Members',
        title: 'Members',
      },
      create: {
        success: 'Member salvo com sucesso',
      },
      update: {
        success: 'Member salvo com sucesso',
      },
      destroy: {
        success: 'Member deletado com sucesso',
      },
      destroyAll: {
        success: 'Member(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Member',
      },
      fields: {
        id: 'Id',
        'memberID': 'MemberID',
        'name': 'Name',
        'membershipType': 'Membership Type',
        'membershipStatus': 'Membership Status',
        'membershipAction': 'Membership Action',
        'financialMember': 'FinancialMember',
        'financialAction': 'Financial Action',
        'nameofIntroducer': 'Name of Introducer (if have)',
        'areYourIntroducerIsJCIMember': 'Are Your Introducer Is JCI Member?',
        'tShirtSize': 'TShirt Size',
        'bloodType': 'BloodType',
        'maritalStatus': 'Marital Status',
        'companyName': 'Company Name',
        'positionInCompany': 'Position In Company',
        'howKnowJCI': 'How your know about JCI MCE',
        'individual': 'Do u interested in Individual Project or Event',
        'business': 'Do u interested in Business Project or Event',
        'community': 'Do u interested in Community Project or Event',
        'international': 'Do u interested in International Project or Event',
        'obligation': 'Obligation',
        'informationGiven': 'I hereby declare that the above information is true and correct',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        'membershipType': {
          'Member': 'Member',
          'Alumni': 'Alumni',
        },
        'membershipStatus': {
          'Pending_Approve': 'Pending_Approve',
          'Provisional': 'Provisional',
          'Member': 'Member',
          'Alumni': 'Alumni',
        },
        'membershipAction': {
          'Approve_Provisional': 'Approve_Provisional',
          'Approve_Member': 'Approve_Member',
          'Approve_Alumni': 'Approve_Alumni',
          'Reject': 'Reject',
        },
        'financialMember': {
          'Awaiting_Payment': 'Awaiting_Payment',
          'Paid_Provisional': 'Paid_Provisional',
          'Paid_Member': 'Paid_Member',
          'Paid_Alumni': 'Paid_Alumni',
        },
        'financialAction': {
          'Paid_Provisonal': 'Paid_Provisonal',
          'Paid_Member': 'Paid_Member',
          'Paid_Alumni': 'Paid_Alumni',
        },
        'areYourIntroducerIsJCIMember': {
          'Yes': 'Yes',
          'No': 'No',
        },
        'tShirtSize': {
          'XS': 'XS',
          'S': 'S',
          'M': 'M',
          'L': 'L',
          'XL': 'XL',
          'XXL': 'XXL',
          'XXXL': 'XXXL',
          'XXXXL': 'XXXXL',
        },
        'bloodType': {
          'A_Minus': 'A_Minus',
          'A_Plus': 'A_Plus',
          'B_Minus': 'B_Minus',
          'B_Plus': 'B_Plus',
          'AB_Minus': 'AB_Minus',
          'AB_Plus': 'AB_Plus',
          'O_Minus': 'O_Minus',
          'O_Plus': 'O_Plus',
        },
        'maritalStatus': {
          'Single': 'Single',
          'Married': 'Married',
          'Divorced': 'Divorced',
          'Widowed': 'Widowed',
        },
        'howKnowJCI': {
          'Family': 'Family',
          'Friend': 'Friend',
          'Social_Media': 'Social_Media',
          'Event': 'Event',
          'Newspaper': 'Newspaper',
        },
        'obligation': {
          'Yes': 'Yes',
          'No': 'No',
        },
        'informationGiven': {
          'Yes': 'Yes',
          'No': 'No',
        },
      },
      new: {
        title: 'Novo Member',
      },
      view: {
        title: 'Visualizar Member',
      },
      importer: {
        title: 'Importar Members',
        fileName: 'member_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    activity: {
      name: 'Activity',
      label: 'Activities',
      menu: 'Activities',
      exporterFileName: 'Activity_exportados',
      list: {
        menu: 'Activities',
        title: 'Activities',
      },
      create: {
        success: 'Activity salvo com sucesso',
      },
      update: {
        success: 'Activity salvo com sucesso',
      },
      destroy: {
        success: 'Activity deletado com sucesso',
      },
      destroyAll: {
        success: 'Activity(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Activity',
      },
      fields: {
        id: 'Id',
        'eventName': 'Event Name',
        'dateRange': 'Date',
        'date': 'Date',
        'time': 'Time',
        'venue': 'Venue',
        'eventDescription': 'Event Description',
        'memberPrice': 'MemberPrice',
        'jCIPrice': 'JCIPrice',
        'nonMemberPrice': 'NonMemberPrice',
        'proposal': 'Proposal',
        'enhancementReport': 'Enhancement Report',
        'financialReport': 'Financial Report',
        'status': 'Status',
        'statusAction': 'StatusAction',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        'status': {
          'Awaiting_Approved': 'Awaiting_Approved',
          'Approved': 'Approved',
          'Rejected': 'Rejected',
        },
        'statusAction': {
          'Approve': 'Approve',
          'Reject': 'Reject',
        },
      },
      new: {
        title: 'Novo Activity',
      },
      view: {
        title: 'Visualizar Activity',
      },
      importer: {
        title: 'Importar Activities',
        fileName: 'activity_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    business: {
      name: 'Business',
      label: 'Businesses',
      menu: 'Businesses',
      exporterFileName: 'Business_exportados',
      list: {
        menu: 'Businesses',
        title: 'Businesses',
      },
      create: {
        success: 'Business salvo com sucesso',
      },
      update: {
        success: 'Business salvo com sucesso',
      },
      destroy: {
        success: 'Business deletado com sucesso',
      },
      destroyAll: {
        success: 'Business(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Business',
      },
      fields: {
        id: 'Id',
        'companyName': 'Company Name',
        'industry': 'Industry',
        'address': 'Address',
        'contact': 'Contact',
        'email': 'Email',
        'website': 'Website',
        'image': 'Image (5 images only)',
        'companyDescription': 'Company Description',
        'memberBenefit': 'Member Benefit',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        'industry': {
          'Agriculture': 'Agriculture',
          'Fishing': 'Fishing',
          'Mining_and_Quarrying': 'Mining_and_Quarrying',
          'Oil_and_Gas': 'Oil_and_Gas',
          'Manufacturing': 'Manufacturing',
          'Construction': 'Construction',
          'Commerce': 'Commerce',
          'Utility': 'Utility',
          'Wholesale_and_Retail': 'Wholesale_and_Retail',
          'Hotel': 'Hotel',
          'Restaurants': 'Restaurants',
          'Logistic_and_Transport': 'Logistic_and_Transport',
          'Arts_and_Entertainment': 'Arts_and_Entertainment',
          'Sport': 'Sport',
          'Information_Technology': 'Information_Technology',
          'Transportation': 'Transportation',
          'Financial_and_Insurance': 'Financial_and_Insurance',
          'Real_Estate_and_Rental': 'Real_Estate_and_Rental',
          'Waste_Management': 'Waste_Management',
          'Education': 'Education',
          'Health': 'Health',
          'Social_Work': 'Social_Work',
          'Safety_and_Defense': 'Safety_and_Defense',
          'Forestry': 'Forestry',
          'Sales_and_Service': 'Sales_and_Service',
        },
      },
      new: {
        title: 'Novo Business',
      },
      view: {
        title: 'Visualizar Business',
      },
      importer: {
        title: 'Importar Businesses',
        fileName: 'business_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    gallery: {
      name: 'Gallery',
      label: 'Galleries',
      menu: 'Galleries',
      exporterFileName: 'Gallery_exportados',
      list: {
        menu: 'Galleries',
        title: 'Galleries',
      },
      create: {
        success: 'Gallery salvo com sucesso',
      },
      update: {
        success: 'Gallery salvo com sucesso',
      },
      destroy: {
        success: 'Gallery deletado com sucesso',
      },
      destroyAll: {
        success: 'Gallery(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Gallery',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'image': 'Image',
        'category': 'Category',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {

      },
      new: {
        title: 'Novo Gallery',
      },
      view: {
        title: 'Visualizar Gallery',
      },
      importer: {
        title: 'Importar Galleries',
        fileName: 'gallery_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    type: {
      name: 'Type',
      label: 'Types',
      menu: 'Types',
      exporterFileName: 'Type_exportados',
      list: {
        menu: 'Types',
        title: 'Types',
      },
      create: {
        success: 'Type salvo com sucesso',
      },
      update: {
        success: 'Type salvo com sucesso',
      },
      destroy: {
        success: 'Type deletado com sucesso',
      },
      destroyAll: {
        success: 'Type(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Type',
      },
      fields: {
        id: 'Id',
        'text': 'Text',
        'integerRange': 'Integer',
        'integer': 'Integer',
        'decimalRange': 'Decimal',
        'decimal': 'Decimal',
        'dateRange': 'Date',
        'date': 'Date',
        'datetimeRange': 'Datetime',
        'datetime': 'Datetime',
        'booleanSwitch': 'BooleanSwitch',
        'booleanCheckbox': 'BooleanCheckbox',
        'booleanRadio': 'BooleanRadio',
        'enumerator': 'Enumerator',
        'relationOne': 'RelationOne',
        'relationMany': 'RelationMany',
        'relationUserOne': 'RelationUserOne',
        'relationUserMany': 'RelationUserMany',
        'file': 'File',
        'image': 'Image',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        'enumerator': {
          'Start': 'Start',
          'Progress': 'Progress',
          'End': 'End',
        },
      },
      new: {
        title: 'Novo Type',
      },
      view: {
        title: 'Visualizar Type',
      },
      importer: {
        title: 'Importar Types',
        fileName: 'type_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    galleryCategory: {
      name: 'Gallery Category',
      label: 'Gallery Categories',
      menu: 'Gallery Categories',
      exporterFileName: 'Gallery Category_exportados',
      list: {
        menu: 'Gallery Categories',
        title: 'Gallery Categories',
      },
      create: {
        success: 'Gallery Category salvo com sucesso',
      },
      update: {
        success: 'Gallery Category salvo com sucesso',
      },
      destroy: {
        success: 'Gallery Category deletado com sucesso',
      },
      destroyAll: {
        success: 'Gallery Category(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Gallery Category',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {

      },
      new: {
        title: 'Novo Gallery Category',
      },
      view: {
        title: 'Visualizar Gallery Category',
      },
      importer: {
        title: 'Importar Gallery Categories',
        fileName: 'galleryCategory_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Editar Perfil',
      success: 'Perfil atualizado com sucesso',
    },
    createAnAccount: 'Criar uma conta',
    rememberMe: 'Lembrar-me',
    forgotPassword: 'Esqueci minha senha',
    signin: 'Entrar',
    signup: 'Registrar',
    signout: 'Sair',
    alreadyHaveAnAccount: 'Já possui uma conta? Entre.',
    signinWithAnotherAccount: 'Entrar com outra conta',
    emailUnverified: {
      message: `Por favor, confirme seu email em <strong>{0}</strong> para continuar.`,
      submit: `Reenviar confirmação por email`,
    },
    emptyPermissions: {
      message: `Você ainda não possui permissões. Aguarde o administrador conceder seus privilégios.`,
    },
    passwordResetEmail: {
      message: 'Enviar email de redefinição de senha',
      error: `Email não encontrado`,
    },
    passwordReset: {
      message: 'Alterar senha',
    },
    emailAddressVerificationEmail: {
      error: `Email não encontrado`,
    },
    verificationEmailSuccess: `Verificação de email enviada com sucesso`,
    passwordResetEmailSuccess: `Email de redefinição de senha enviado com sucesso`,
    passwordResetSuccess: `Senha alterada com sucesso`,
    verifyEmail: {
      success: 'Email verificado com sucesso',
      message:
        'Aguarde um momento, seu email está sendo verificado...',
    },
  },

  roles: {
    owner: {
      label: 'Proprietário',
      description: 'Acesso completo a todos os recursos',
    },
    editor: {
      label: 'Editor',
      description: 'Acesso para edição a todos os recursos',
    },
    viewer: {
      label: 'Visualizador',
      description:
        'Acesso de visualização a todos os recursos',
    },
    auditLogViewer: {
      label: 'Visualizador de Registros de Autoria',
      description:
        'Acesso de visualização dos registros de autoria',
    },
    iamSecurityReviewer: {
      label: 'Revisor de segurança',
      description: `Acesso total para gerenciar as funções do usuário`,
    },
    entityEditor: {
      label: 'Editor de Entidades',
      description: 'Acesso de edição a todas as entidades',
    },
    entityViewer: {
      label: 'Visualizador de Entidades',
      description:
        'Acesso de visualização a todas as entidades',
    },
    userAccountEditor: {
      label: 'Editor de UserAccounts',
      description: 'Acesso de edição aos UserAccounts',
    },
    userAccountViewer: {
      label: 'Visualizador de UserAccounts',
      description: 'Acesso de visualização aos UserAccounts',
    },
    memberEditor: {
      label: 'Editor de Members',
      description: 'Acesso de edição aos Members',
    },
    memberViewer: {
      label: 'Visualizador de Members',
      description: 'Acesso de visualização aos Members',
    },
    activityEditor: {
      label: 'Editor de Activities',
      description: 'Acesso de edição aos Activities',
    },
    activityViewer: {
      label: 'Visualizador de Activities',
      description: 'Acesso de visualização aos Activities',
    },
    businessEditor: {
      label: 'Editor de Businesses',
      description: 'Acesso de edição aos Businesses',
    },
    businessViewer: {
      label: 'Visualizador de Businesses',
      description: 'Acesso de visualização aos Businesses',
    },
    galleryEditor: {
      label: 'Editor de Galleries',
      description: 'Acesso de edição aos Galleries',
    },
    galleryViewer: {
      label: 'Visualizador de Galleries',
      description: 'Acesso de visualização aos Galleries',
    },
    typeEditor: {
      label: 'Editor de Types',
      description: 'Acesso de edição aos Types',
    },
    typeViewer: {
      label: 'Visualizador de Types',
      description: 'Acesso de visualização aos Types',
    },
    galleryCategoryEditor: {
      label: 'Editor de Gallery Categories',
      description: 'Acesso de edição aos Gallery Categories',
    },
    galleryCategoryViewer: {
      label: 'Visualizador de Gallery Categories',
      description: 'Acesso de visualização aos Gallery Categories',
    },
  },

  iam: {
    title: 'Gerenciamento de usuários e permissões',
    menu: 'IAM',
    disable: 'Desabilitar',
    disabled: 'Desabilitado',
    enabled: 'Habilitado',
    enable: 'Habilitar',
    doEnableSuccess: 'Usuário habilitado com sucesso',
    doDisableSuccess: 'Usuário desabilitado com sucesso',
    doDisableAllSuccess:
      'Usuário(s) desabilitado(s) com sucesso',
    doEnableAllSuccess:
      'Usuário(s) habilidatos com sucesso',
    doAddSuccess: 'Usuário(s) salvos com sucesso',
    doUpdateSuccess: 'Usuário salvo com sucesso',
    viewBy: 'Visualizar por',
    users: {
      name: 'users',
      label: 'Usuários',
      exporterFileName: 'usuarios_exportados',
      doRemoveAllSelectedSuccess:
        'Permissões removidas com sucesso',
    },
    roles: {
      label: 'Perfis',
      doRemoveAllSelectedSuccess:
        'Permissões removidas com sucesso',
    },
    edit: {
      title: 'Editar usuário',
    },
    new: {
      title: 'Novo(s) Usuário(s)',
      titleModal: 'Novo Usuário',
      emailsHint:
        'Separe múltiplos endereços de e-mail usando a vírgula.',
    },
    view: {
      title: 'Visualizar Usuário',
      activity: 'Atividades',
    },
    importer: {
      title: 'Importar Usuários',
      fileName: 'usuarios_template_importacao',
      hint:
        'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
    },
    errors: {
      userAlreadyExists: 'Usuário com este email já existe',
      userNotFound: 'Usuário não encontrado',
      disablingHimself: `Você não pode desativar-se`,
      revokingOwnPermission: `Você não pode revogar sua própria permissão de proprietário`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Id de autenticação',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Nome',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      status: 'Estado',
      disabled: 'Desativado',
      phoneNumber: 'Telefone',
      role: 'Perfil',
      createdAt: 'Criado em',
      updatedAt: 'Atualizado em',
      roleUser: 'Perfil/Usuário',
      roles: 'Perfis',
      createdAtRange: 'Criado em',
      password: 'Senha',
      rememberMe: 'Lembrar-me',
    },
    enabled: 'Habilitado',
    disabled: 'Desabilitado',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} é inválido',
    },
  },

  auditLog: {
    menu: 'Registros de Auditoria',
    title: 'Registros de Auditoria',
    exporterFileName: 'registros_autoria_exportados',
    entityNamesHint:
      'Separe múltiplas entidades por vírgula',
    fields: {
      id: 'Id',
      timestampRange: 'Período',
      entityName: 'Entidade',
      entityNames: 'Entidades',
      entityId: 'ID da Entidade',
      action: 'Ação',
      values: 'Valores',
      timestamp: 'Data',
      createdByEmail: 'Email do Usuário',
    },
  },
  settings: {
    title: 'Configurações',
    menu: 'Configurações',
    save: {
      success:
        'Configurações salvas com sucesso. A página irá recarregar em {0} para que as alterações tenham efeito.',
    },
    fields: {
      theme: 'Tema',
      primaryColor: 'Cor Primária',
      secondaryColor: 'Cor Secundária',
    },
    colors: {
      default: 'Padrão',
      amber: 'Âmbar',
      blue: 'Azul',
      cyan: 'Ciano',
      deep_orange: 'Laranja Escuro',
      deep_purple: 'Roxo Escuro',
      green: 'Azul',
      indigo: 'Índigo',
      light_blue: 'Azul Fraco',
      light_green: 'Verde Fraco',
      lime: 'Limão',
      orange: 'Laranja',
      pink: 'Rosa',
      purple: 'Roxo',
      red: 'Vermelho',
      teal: 'Verde Azulado',
      yellow: 'Amarelo',
      grey: 'Cinza',
      blue_grey: 'Azul Cinza',
      brown: 'Marrom',
    },
  },
  home: {
    menu: 'Inicial',
    message: `Esta página usa dados falsos apenas para fins de demonstração. Você pode editá-la em frontend/src/app/home/home.component.html.`,
    charts: {
      day: 'Dia',
      red: 'Vermelho',
      green: 'Verde',
      yellow: 'Amarelho',
      grey: 'Cinza',
      blue: 'Azul',
      orange: 'Laranja',
      months: {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
      },
      eating: 'Comendo',
      drinking: 'Bebendo',
      sleeping: 'Dormindo',
      designing: 'Projetando',
      coding: 'Codificando',
      cycling: 'Pedalando',
      running: 'Correndo',
      customer: 'Cliente',
    },
  },
  errors: {
    backToHome: 'Voltar a página inicial',
    403: `Desculpe, você não tem acesso a esta página`,
    404: 'Desculpe, a página que você visitou não existe',
    500: 'Desculpe, o servidor está relatando um erro',
    forbidden: {
      message: 'Acesso negado',
    },
    validation: {
      message: 'Ocorreu um erro',
    },
    defaultErrorMessage: 'Ops, ocorreu um erro',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} é inválido',
      required: '${path} é obrigatório',
      oneOf:
        '${path} deve ser um dos seguintes valores: ${values}',
      notOneOf:
        '${path} não deve ser um dos seguintes valores: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} deve ser um ${type}`;
      },
    },
    string: {
      length: '${path} deve possuir ${length} caracteres',
      min:
        '${path} deve possuir ao menos ${min} caracteres',
      max:
        '${path} deve possui no máximo ${max} caracteres',
      matches:
        '${path} deve respeitar o padrão: "${regex}"',
      email: '${path} deve ser um email válido',
      url: '${path} deve ser uma URL válida',
      trim:
        '${path} deve ser uma palavra sem espaços em branco',
      lowercase: '${path} deve ser minúsculo',
      uppercase: '${path} deve ser maiúsculo',
      selected: '${path} deve ser selecionado',
    },
    number: {
      min: '${path} deve ser maior ou igual a ${min}',
      max: '${path} deve ser menor ou igual a ${max}',
      lessThan: '${path} deve ser menor que ${less}',
      moreThan: '${path} deve ser maior que ${more}',
      notEqual: '${path} não deve ser igual a ${notEqual}',
      positive: '${path} deve ser um número positivo',
      negative: '${path} deve ser um número negativo',
      integer: '${path} deve ser um inteiro',
      type: '${path} deve ser um número',
    },
    date: {
      min: '${path} deve ser posterior a ${min}',
      max: '${path} deve ser mais cedo do que ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} não pode ter atributos não especificados no formato do objeto',
    },
    array: {
      min: '${path} deve possuir ao menos ${min} itens',
      max: '${path} deve possuir no máximo ${max} itens',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'Você deve fazer upload de uma imagem',
    size:
      'O arquivo é muito grande. O tamanho máximo permitido é {0}',
    formats: `Formato inválido. Deve ser: '{0}'.`,
  },
  importer: {
    line: 'Linha',
    status: 'Estado',
    pending: 'Pendente',
    imported: 'Importado',
    error: 'Erro',
    total: `{0} importado, {1} pendente e {2} com erro`,
    importedMessage: `Processados {0} de {1}.`,
    noNavigateAwayMessage:
      'Não saia desta página ou a importação será interrompida.',
    completed: {
      success:
        'Importação concluída. Todas as linhas foram importadas com sucesso.',
      someErrors:
        'O processamento foi concluído, mas algumas linhas não puderam ser importadas.',
      allErrors:
        'Importação falhou. Não há linhas válidas.',
    },
    form: {
      downloadTemplate: 'Baixe o modelo',
      hint:
        'Clique ou arraste o arquivo para esta área para continuar.',
    },
    list: {
      discardConfirm:
        'Você tem certeza? Dados não importados serão perdidos.',
    },
    errors: {
      invalidFileEmpty: 'O arquivo está vazio',
      invalidFileExcel:
        'Apenas arquivos Excel (.xlsx) são permitidos',
      invalidFileUpload:
        'Arquivo inválido. Verifique se você está usando a última versão do modelo.',
      importHashRequired: 'Hash de importação é necessário',
      importHashExistent: 'Dados já foram importados',
    },
  },

  paginator: {
    itemsPerPageLabel: 'Itens por página:',
    nextPageLabel: 'Próxima',
    previousPageLabel: 'Anterior',
    firstPageLabel: 'Primeira página',
    lastPageLabel: 'Última página',
    getRangeLabel: {
      zero: '0 de {0}',
      interval: '{0} - {1} de {2}',
    },
  },

  datetime: {
    upSecondLabel: 'Adicionar um segundo',
    downSecondLabel: 'Diminuir um segundo',
    upMinuteLabel: 'Adicionar um minuto',
    downMinuteLabel: 'Diminuir um minuto',
    upHourLabel: 'Adicionar uma hora',
    downHourLabel: 'Diminuir uma hora',
    prevMonthLabel: 'Mês anterior',
    nextMonthLabel: 'Mês seguinte',
    prevYearLabel: 'Ano anterior',
    nextYearLabel: 'Ano seguinte',
    prevMultiYearLabel: '21 anos anteriores',
    nextMultiYearLabel: '21 anos próximos',
    switchToMonthViewLabel: 'Alterar para visão mensal',
    switchToMultiYearViewLabel: 'Escolher mês e ano',
    cancelBtnLabel: 'Cancelar',
    setBtnLabel: 'Confirmar',
    rangeFromLabel: 'De',
    rangeToLabel: 'Até',
    hour12AMLabel: 'AM',
    hour12PMLabel: 'PM',
  },

  table: {
    noData: 'Não há dados',
    loading: 'Carregando...',
  },

  autocomplete: {
    loading: 'Carregando...',
  },

  imagesViewer: {
    noImage: 'Sem imagem',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Sua conta está desativada',
    'auth/user-not-found': `Desculpe, não reconhecemos suas credenciais`,
    'auth/wrong-password': `Desculpe, não reconhecemos suas credenciais`,
    'auth/weak-password': 'Esta senha é muito fraca',
    'auth/email-already-in-use':
      'O email já está sendo usado',
    'auth/invalid-email':
      'Por favor forneça um email válido',
    'auth/account-exists-with-different-credential':
      'O email já está em uso para um método de autenticação diferente.',
    'auth/credential-already-in-use':
      'Credenciais já estão em uso',
  },
};

export default ptBR;
